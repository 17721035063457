import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import auditService from '../services/auditService';

const RouteInterceptor = () => {
  const location = useLocation();

  useEffect(() => {
    // Isso será executado toda vez que a rota mudar
    // console.log('Rota alterada para:', location.pathname);
    
    // Aqui você pode executar qualquer ação
    // Exemplo: enviar evento para o Google Analytics
    window.gtag('event', 'page_view', { page_path: location.pathname });

    auditService.logAudit('RouteInterceptor', 'navegacao',location.pathname);

  }, [location]);

  return null; // Não precisa renderizar nada
};

export default RouteInterceptor;
