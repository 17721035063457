import React, { useEffect, useState } from 'react';
import { 
  DataGrid, 
  GridToolbarContainer, 
  GridToolbarExport, 
  gridClasses } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Snackbar, SnackbarContent } from '@mui/material';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import AddressDialog from './AddressDialog.js';
  
import env from '../../config.js';
import axios from 'axios';

import './Address.scss';

const Address = () => {
  const [objects, setObjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const entity = 'enderecos';
  const backendUrl = env.backendUrl;
  const [successMessage, setSuccessMessage] = useState('');
  const initialState = {
    servico_id: '',
    endereco: '',
    rota_cidade: '',
    rota_estado: '',
    rota_zip: ''
  }
  const [object, setObject] = useState(initialState);

  useEffect(() => {
    fetchObjects();
  }, []);

  const fetchObjects = () => {    
    let service_id = localStorage.getItem('service_id');
    const url = backendUrl  + '/' +  entity + '/servico/' + service_id;

    axios.get(url)
    .then(res => {
      const objects = res.data;
      setObjects(objects)
    })  
    .catch(err => {
      if(err.response.status === 404){
        setObjects([]);
      }else{
        console.error(err);
      }
    });
  }

  const onDelClick = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      const url = backendUrl + '/' + entity + '/' + id;

      axios.delete(url)
      .then(response => { 
        if (response.status === 200) {
          setOpenSnack(true);
          setSuccessMessage("Success!");
          fetchObjects();
        }
        else {
          alert('Something went wrong!');
        }  
      })
      .catch(err => {
        console.error(err);
      });
    }
  }

  const saveSuccess = () => {
    setOpen(false);
    fetchObjects();
    setOpenSnack(true);
    setSuccessMessage("Success!");
  };

  const handleClickOpenModal = () => { 
    setObject(initialState);
    setOpen(true);
  };

  // Abre o modal e preenche os dados do projeto
  const handleClickEdit = (params) => {
    const project = params.row;
    setObject(project); // Define o projeto selecionado no estado
    setOpen(true); // Abre o modal
  };

  const handleClose = () => setOpen(false);

  const columns = [
    { 
      field: 'id', 
      headerName: 'ID', 
      renderCell: params => params.row.id 
    }, 
    { 
      field: 'servico_id', 
      headerName: 'Service ID', 
      renderCell: params => params.row.servico_id 
    },
    { 
      field: 'endereco', 
      headerName: 'Address', 
      renderCell: params => params.row.endereco 
    },
    { 
      field: 'rota_cidade', 
      headerName: 'City', 
      renderCell: params => params.row.rota_cidade || 'N/A' // Se for null, exibe 'N/A'
    },
    { 
      field: 'rota_estado', 
      headerName: 'State', 
      renderCell: params => params.row.rota_estado || 'N/A' 
    },
    { 
      field: 'rota_zip', 
      headerName: 'ZIP', 
      renderCell: params => params.row.rota_zip || 'N/A' 
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      filterable: false,
      renderCell: params => (
        <IconButton onClick={() => handleClickEdit(params)}>
          <EditIcon color="primary" />
        </IconButton>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      filterable: false,
      renderCell: params => (
        <IconButton onClick={() => onDelClick(params.row.id)}>
          <DeleteIcon color="error" />
        </IconButton>
      )
    }
  ];
  

  return  (
    <div id="objects" className="container">
      <h1>Address</h1>
      <Stack mt={2} mb={2}>
        {/* <Add addObject={addObject} />
         */}
        <div>
          <Button variant="contained" onClick={handleClickOpenModal}>
            New Address
          </Button>
          <AddressDialog
              open={open}
              onClose={handleClose}
              saveSuccess={saveSuccess}
              object={object}
              setObject={setObject}
            />
        </div>
      </Stack>
      <DataGrid 
        // autoHeight 
        // loading={objects.length <= 0}
        rows={objects} 
        columns={columns} 
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={[5, 10, 20]}
        components={{ Toolbar: CustomToolbar }}/>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
      >
        <SnackbarContent
          message={successMessage}
          style={{ backgroundColor: 'green' }}
        />
      </Snackbar>
    </div>
)};

function CustomToolbar() {
  return (
    <GridToolbarContainer 
      className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default Address; 