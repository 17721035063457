import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import env from '../../config';

function AddressDialog({    
    open,
    onClose,
    saveSuccess,
    object,
    setObject
}) {
    const backendUrl = env.backendUrl;
    const entity = 'enderecos';

    const handleChange = (event) => { 
        setObject({...object, [event.target.name]: event.target.value});
    };
    
    const handleSave = () => {
        let service_id = localStorage.getItem('service_id');
        object.servico_id = service_id;
        
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        
        if (object.id) {
            // object.frequencia = 'aaa';
            const data = JSON.stringify(object);

            const url = backendUrl + '/' + entity + '/' + object.id;
            axios.put(url, data, config)
                .then(response => {
                    if (response.status === 200) {
                        saveSuccess();
                    }
                    else {
                        alert('Something went wrong!');
                    }
                })
                .catch(err => console.error(err))
        } else {
            const data = JSON.stringify(object);
            const url = backendUrl + '/' + entity;
            axios.post(url, data, config)
                .then(response => {
                    if (response.status === 200) {
                        saveSuccess();
                    }
                    else {
                        alert('Something went wrong!');
                    }
                })
                .catch(err => console.error(err))
        }

   
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
            <DialogTitle>Service</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={1}>

                    <TextField
                        label="Address (*)"
                        name="endereco"
                        type="text"
                        variant="standard"
                        value={object.endereco || ''}
                        onChange={handleChange}
                    />

                    <TextField
                        label="City"
                        name="rota_cidade"
                        type="text"
                        variant="standard"
                        value={object.rota_cidade || ''}
                        onChange={handleChange}
                    />

                    <TextField
                        label="State"
                        name="rota_estado"
                        type="text"
                        variant="standard"
                        value={object.rota_estado || ''}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Zip Code"
                        name="rota_zip"
                        type="text"
                        variant="standard"
                        value={object.rota_zip || ''}
                        onChange={handleChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>

    );
}

export default AddressDialog;
