// src/services/auditService.js

// const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
import env from '../config.js';

const BASE_URL = env.backendUrl;

const auditService = {
    logAudit: async (user_agent, event, details) => {
        try {
            const user = JSON.parse(sessionStorage.getItem("user"));
            let auditData;
            
            if(user && user.email){
                auditData = {
                    user_id: JSON.parse(sessionStorage.getItem("user")).email,
                    event: event,
                    details: details,
                    user_agent: user_agent
                }
            
            }else{
                auditData = {
                    user_id: 'nao logado',
                    event: event,
                    details: details,
                    user_agent: user_agent
                }
            }

            
            const response = await fetch(`${BASE_URL}/audit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(auditData),
            });

            if (!response.ok) {
                throw new Error('Failed to log audit event');
            }

            return await response.json();
        } catch (error) {
            console.error('Error logging audit:', error);
            throw error;
        }
    },
};

export default auditService;
