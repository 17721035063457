import React, { useEffect, useState } from 'react';
import { 
  DataGrid, 
  GridToolbarContainer, 
  GridToolbarExport, 
  gridClasses } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Snackbar, SnackbarContent } from '@mui/material';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ServiceDialog from './ServiceDialog.js';
import { useNavigate } from 'react-router-dom';
  
import env from '../../config';
import axios from 'axios';

import './Service.scss';

const Service = () => {
  const [objects, setObjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const entity = 'servicos';
  const backendUrl = env.backendUrl;
  const [successMessage, setSuccessMessage] = useState('');
  const initialState = {
    rota_tipo: '',
    fall: false,
    valor: '',
    frequencia: ''
  }
  const [object, setObject] = useState(initialState);
  const navigate = useNavigate();

  useEffect(() => {
    fetchObjects();
  }, []);

  const fetchObjects = () => {    
    let project_id = localStorage.getItem('project_id');
    const url = backendUrl  + '/' +  entity + '/project/' + project_id;

    axios.get(url)
    .then(res => {
      const objects = res.data;
      setObjects(objects)
    })  
    .catch(err => {
      if(err.response.status === 404){
        setObjects([]);
      }else{
        console.error(err);
      }
    });
  }

  const onDelClick = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      const url = backendUrl + '/' + entity + '/' + id;

      axios.delete(url)
      .then(response => { 
        if (response.status === 200) {
          setOpenSnack(true);
          setSuccessMessage("Success!");
          fetchObjects();
        }
        else {
          alert('Something went wrong!');
        }  
      })
      .catch(err => {
        console.error(err);
      });
    }
  }

  const saveSuccess = () => {
    setOpen(false);
    fetchObjects();
    setOpenSnack(true);
    setSuccessMessage("Success!");
  };

  const handleClickOpenModal = () => { 
    setObject(initialState);
    setOpen(true);
  };

  // Abre o modal e preenche os dados do projeto
  const handleClickEdit = (params) => {
    const project = params.row;
    setObject(project); // Define o projeto selecionado no estado
    setOpen(true); // Abre o modal
  };

  const handleClose = () => setOpen(false);

  const openEnderecos = (service_id) => {
    // Armazenar dados no localStorage
    localStorage.setItem('service_id', service_id );

    //navigate to the services route
    navigate('/address');
  } 

  const columns = [
    { field: 'id', headerName: 'Service', renderCell: row => row.id },
    { field: 'project', headerName: 'Project',
      renderCell: row => {
        //console.log(row); // Isso vai imprimir todos os atributos disponíveis no objeto `row`
        return <span>{row.row.projeto_id}</span>; // Exemplo de como acessar um valor específico
      }
    },
    { field: 'titulo', headerName: 'Title', renderCell: row => row.titulo  },
    { field: 'rota_tipo', headerName: 'Type', renderCell: params => params.row.rota_tipo },
    { field: 'frequencia', headerName: 'Frequency', width: 300, renderCell: params => params.row.frequencia },
    { field: 'valor', headerName: 'Value', renderCell: params => '$' + params.row.valor },
    // { field: 'enderecos', headerName: 'Address', renderCell: params => params.row.enderecos.length },
    {
      field: 'enderecos', 
      headerName: 'Enderecos', 
      sortable: false,
      filterable: false,
      renderCell: (params) => { 
        return <Button 
          variant="contained" 
          data={params}
          onClick={() => openEnderecos(params.row.id)}>{params.row.enderecos.length}</Button>
      }
    },
    {
      field: 'edit', 
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <IconButton onClick={() => handleClickEdit(params)}>
        <EditIcon color="primary" />
      </IconButton>
      }
    },  
    {
      field: 'id2', 
      headerName: 'Delete', 
      sortable: false,
      filterable: false,
      renderCell: row => 
        <IconButton onClick={() => onDelClick(row.id)}>
          <DeleteIcon color="error" />
        </IconButton>
    }  
  ];

  return  (
    <div id="objects" className="container">
      <h1>Services</h1>
      <Stack mt={2} mb={2}>
        {/* <Add addObject={addObject} />
         */}
        <div>
          <Button variant="contained" onClick={handleClickOpenModal}>
            New Service
          </Button>
          <ServiceDialog
              open={open}
              onClose={handleClose}
              saveSuccess={saveSuccess}
              object={object}
              setObject={setObject}
            />
        </div>
      </Stack>
      <DataGrid 
        // autoHeight 
        // loading={objects.length <= 0}
        rows={objects} 
        columns={columns} 
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={[5, 10, 20]}
        components={{ Toolbar: CustomToolbar }}/>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
      >
        <SnackbarContent
          message={successMessage}
          style={{ backgroundColor: 'green' }}
        />
      </Snackbar>
    </div>
)};

function CustomToolbar() {
  return (
    <GridToolbarContainer 
      className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default Service; 